import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal';

import { clientSatisfactionData } from './modules';
import { clientSatisfactionList } from './modules';

import './ClientSatisfaction.scss';

const ClientSatisfaction = () => {
  const { t } = useTranslation();
  const [content, setContent] = useState(clientSatisfactionData);

  useEffect(() => {
    setContent(clientSatisfactionData);
  }, []);

  return (
    <div>
      <div className="cs-container">
        <div className="cs-row">
          <Fade>
            <h1 className="cs-title">
              {t('policy clientSatisfaction title')}
            </h1>
          </Fade>
          <Fade bottom>
            {clientSatisfactionList().map((item) => (
              <p className="cs-list">{item.list}</p>
            ))}
            <div style={{ marginTop: '30px' }} className="cs-list">
              Ovdje možete pogledati našu politiku kvaliteta i
              okolinskog upravljanja <br />
              <a
                href="/Quality Policy ATT.PDF"
                style={{ color: '#0072bb', fontSize: '16px' }}
                target="_blank"
              >
                Politika kvaliteta
              </a>{' '}
              i{' '}
              <a
                href="/ATT Environmental Management Policy.pdf"
                style={{ color: '#0072bb', fontSize: '16px' }}
                target="_blank"
              >
                Politika okolinskog upravljanja
              </a>
            </div>
          </Fade>
        </div>
        <div className="cs-row">
          <Fade>
            <img src={content.image} alt="" className="cs-image" />
          </Fade>
          <Fade bottom>
            <p className="cs-text">
              {t('policy clientSatisfaction text')}
            </p>
            <p className="cs-text">
              {t('policy clientSatisfaction text2')}
            </p>
            <p className="cs-text2">
              {t('policy clientSatisfaction text3')}
            </p>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default ClientSatisfaction;
