import React from 'react';
import ClientSatisfaction from './components/ClientSatisfaction';
import HeroSection from './components/HeroSection';
import Banner from '../Banner';
import { bannerPolicyBig } from '../Banner/modules';
import { useTranslation } from 'react-i18next';
import Certification from './components/Certification';

const Policy = () => {
  const { t } = useTranslation();

  return (
    <div>
      <HeroSection />
      {bannerPolicyBig().map((item) => (
        <Banner {...item} />
      ))}
      <ClientSatisfaction />
      <Certification />
    </div>
  );
};

export default Policy;
